<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/players/${resource.route}`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit" v-if="form">
                <template #field-players>
                    <sc-form-field :form="form" field="players">

                        <div class="player-groups-search">
                            <sc-search @searched="searchPlayers" placeholder="Search Players"/>
                        </div>

                        <sc-table :table="players" class="player-groups-table" v-if="players.data">
                            <template #headers>
                                <div style="display:none;"></div>
                            </template>

                            <template #rows>
                                <div class="player-groups-table-items">
                                    <label v-for="(p,i) in players.data.data" :for="`player-${p.attributes.name}`"
                                           class="player-groups-table-item"
                                           :key="`player-${p.attributes.name}`">
                                        <input :id="`player-${p.attributes.name}`" type="checkbox" class="player-groups-table-item-input"
                                               v-model="form.fields.players" :key="`player-${p.attributes.name}-input`"
                                               :value="p.id"/>
                                        <span class="player-groups-table-item-text">{{ p.attributes.name }}</span>
                                    </label>
                                </div>

                            </template>
                        </sc-table>
                    </sc-form-field>
                </template>

                <template #field-user_groups>
                    <sc-form-field :form="form" field="user_groups">
                        <div class="player-groups-search">
                            <sc-search @searched="searchGroups" placeholder="Search User Groups"/>
                        </div>

                        <sc-table :table="userGroups" class="player-groups-table" v-if="userGroups.data">
                            <template #headers>
                                <div style="display:none;"></div>
                            </template>

                            <template #rows>
                                <div class="player-groups-table-items">
                                    <label v-for="(g,i) in userGroups.data.data" :for="`group-${g.attributes.name}`"
                                           class="player-groups-table-item"
                                           :key="`player-${g.attributes.name}`">
                                        <input :id="`group-${g.attributes.name}`" type="checkbox" class="player-groups-table-item-input"
                                               v-model="form.fields.user_groups" :key="`group-${g.attributes.name}-input`"
                                               :value="g.id"/>
                                        <span class="player-groups-table-item-text">{{ g.attributes.name }}</span>
                                    </label>
                                </div>

                            </template>
                        </sc-table>
                    </sc-form-field>
                </template>

            </sc-form>
        </div>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
export default {
  name: "edit",
  components: {ScSearch},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,

      // Players
      playerSearch: undefined,
      players: this.$stable.createTable({
        url: 'players',
        fetchArguments: () => {
          if (this.playerSearch) {
            let args = '&filters=';
            args += `{"query":"${this.playerSearch}"}`
            return args
          }
        },
        columns: {}
      }),

      //UserGroups
      groupsSearch: undefined,
      userGroups: this.$stable.createTable({
        url: 'user-groups',
        fetchArguments:() => {
          if (this.groupsSearch) {
            return `&q=${this.groupsSearch}`
          }
        },
        columns: {}
      })
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `Edit ${this.resource.name}`,
      url: `${this.resource.api}/${this.$route.params[this.resource.route_param]}`,
      fields: {
        name: {},
        description: {},
        parent_id: {
          name: 'Parent group*',
          component: 'nr-select',
          options: {
            empty: 'Select a parent player group',
            data: this.fetchResource('player-groups')
          }
        },
        players: {
          default: []
        },
        user_groups: {
          default: []
        }
      },
      success: () => {
        this.$notify.success(`${this.resource.name} updated`)
        this.$router.push(`/app/players/${this.resource.route}`)
      }
    })
    this.form.get()
    .then((res) => {
      if (res.data.players && res.data.players.data.length > 0) {
        this.form.fields.players = res.data.players.data.map(p => p.id);
      }
      if (res.data.user_groups && res.data.user_groups.data.length > 0) {
        this.form.fields.user_groups = res.data.user_groups.data.map(g => g.id);
      }
    });
    this.players.fetch();
    this.userGroups.fetch();
  },
  methods: {

    submit() {
      console.log(this.form.fields);
      this.form.put();
    },

    fetchResource(resource) {
      return this.$talker.api(resource)
        .then((res) => {
          return res.data.data
        })
    },

    searchPlayers(e) {
      this.playerSearch = e;
      this.players.fetch();
    },

    searchGroups(e) {
      this.groupsSearch = e;
      this.userGroups.fetch();
    }
  }

}
</script>

<style scoped lang="scss">
.player-groups-search {
    max-width: 300px;
}

.player-groups-table {
    margin: 0;
    padding: .5em 0 0 0;

    .player-groups-table-items {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: .5em;
        grid-row-gap: .5em;
        padding: .5em 0;

        .player-groups-table-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid #e2e2e2;
            border-radius: .25em;
            transition: all $transition-duration $easing;

            &:hover {
                border-color: $color-primary;
            }

            .player-groups-table-item-input, .player-groups-table-item-text {
                flex: 1;
            }

            .player-groups-table-item-input {
                max-width: 1em;
                padding: 1em;
                margin: 1em;
                min-width: initial;
            }
        }
    }
}
</style>